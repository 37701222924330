.header {
  display: flex;
  position: fixed;
  top: 0;
  width: 100%;
  text-transform: uppercase;
  font-weight: 400;
  font-size: 0.9rem;
  z-index: 7;
  flex-direction: column;
  gap: 0.1rem;
}

.header_logo {
  position: absolute;
  z-index: 8;
  background-color: var(--sandWhite);
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  height: 100%;
  text-transform: none;
  display: flex;
  justify-content: center;
  align-items: center;
}

.header_logo > img {
  display: block;
  max-width: 100%;
  scale: 0.4;
  height: 100%;
}

.header_left,
.header_right {
  padding: 0 3rem 0 0;
  flex: 1 1 0px;
  display: flex !important;
  justify-content: flex-end;
  align-items: center;
  z-index: 7;
  position: relative;
  background-color: var(--sandWhite);
  color: var(--brown);
}

.header_left {
  justify-content: flex-start;
  padding: 0 0 0 3rem;
}

.header_right_ul {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  z-index: 2;
  height: 2.5rem;
}

.header_right_ul {
  display: flex;
  gap: 1rem;
}

.header_right_ul > li > a {
  display: flex;
  margin-right: auto;
}

.header_right_ul > li {
  cursor: pointer;
}

.header_right_ul > li:nth-child(1),
.header_right_ul > li:nth-child(6) {
  display: none;
}

.header_right_ul
  > li:not(:nth-child(2), :nth-child(3))
  > a
  > span:nth-child(2) {
  display: none;
}

.header_right_ul > li:nth-child(2) > a > span:nth-child(1),
.header_right_ul > li:nth-child(3) > a > span:nth-child(1) {
  display: none;
}

.header_right_ul > li > a svg {
  font-size: 2.1rem;
}

/* MEDIA Queries */

@media (max-width: 1100px) {
  .header_right_ul > li:nth-child(2),
  .header_right_ul > li:nth-child(3) {
    display: none;
  }

  .header_left,
  .header_right,
  .header_logo {
    height: 4rem !important;
  }

  .header_right {
    padding: 0 1rem 0 0;
  }

  .header_left {
    padding: 0 0 0 2rem;
  }
}

@media (max-width: 640px) {
  .header {
    top: unset;
    bottom: 0;
    transition: 0.4s;
    flex-direction: column-reverse;
    gap: 0;
    overflow: hidden;
    box-shadow: unset;
  }

  .header_right_ul > li a {
    margin-right: 0rem;
  }

  .header_left,
  .header_right {
    height: 4.6rem !important;
    border-top: 0.5px solid var(--borderSandWhite);
    background-color: var(--sandWhite);
  }

  .header_right_ul > li > a svg {
    font-size: 1.6rem;
  }

  .header_left,
  .header_logo {
    display: none !important;
  }

  .header_right_ul {
    width: 100%;
    height: 100%;
    padding: unset;
    justify-content: space-around;
    gap: 0;
  }

  .header_right_ul > li a,
  .header_right_ul > li {
    flex: 1 1 0px;
    height: 100%;
    width: 100%;
    padding: 0%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    text-transform: capitalize;
    position: relative;
    cursor: pointer;
  }

  .header_right_ul > li:last-child > a {
    margin-right: 0;
  }

  .header_right_ul > li span:nth-child(1) {
    height: 1.5rem;
  }

  .header_right_ul > li span:nth-child(2) {
    font-weight: normal;
  }

  .header_right_ul > li:nth-child(4) {
    display: none;
  }

  .header_right_ul > li:nth-child(1),
  .header_right_ul > li:nth-child(6) {
    display: flex;
  }

  .header_right_ul > li:nth-child(2),
  .header_right_ul > li:nth-child(3) {
    display: flex;
  }

  .header_right_ul > li:nth-child(2) > a > span:nth-child(1),
  .header_right_ul > li:nth-child(3) > a > span:nth-child(1) {
    display: flex;
  }

  .header_right_ul
    > li:not(:nth-child(2), :nth-child(3))
    > a
    > span:nth-child(2) {
    display: flex;
  }

  .header_right {
    position: relative;
    padding: 0;
  }

  .header_right_ul > li span:nth-child(2) {
    font-size: 0.7rem;
    font-weight: normal;
  }
}

@media (max-width: 400px) {
  .header_right_ul > li span:nth-child(2) {
    font-size: 0.8rem;
  }
}
