.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 2rem;
}

.productContainer {
  display: flex;
  flex-direction: column;
  text-align: center;
  width: 100%;
}

.gridContainer > li {
  display: flex;
}

.gridContainer {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(30%, 1fr));
  column-gap: 2rem;
  row-gap: 4rem;
  padding: 0 1rem 3rem;
}

@media (max-width: 1344px) {
  .gridContainer {
    grid-template-columns: repeat(auto-fill, minmax(324px, 1fr));
  }
}

@media (max-width: 640px) {
  .container {
    gap: 2rem;
    padding: 2rem 1rem 0;
  }

  .gridContainer {
    grid-template-columns: repeat(auto-fill, minmax(100%, 1fr));
  }
}
