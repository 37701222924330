.lisOptions {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-size: 0.9rem;
  font-weight: 400;
  cursor: pointer;
}

.lisOptions a {
  display: flex;
  align-items: center;
  gap: 1rem;
}
