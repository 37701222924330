.container {
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 4rem;
  padding: 4rem 0 7rem;
  color: var(--brown);
  width: 100%;
}

.content {
  overflow: hidden;
  background-color: var(--sandWhite);
}

@media (max-width: 1100px) {
  .container > .content {
    width: 85%;
  }

  .container > .content > ol {
    width: 100%;
  }
}

@media (max-width: 640px) {
  .container {
    width: 100%;
    position: relative;
    margin-inline: auto;
    padding: 2rem 2rem 7rem;
  }

  .container > .navigation {
    width: 100%;
    padding: 0 0 0.2rem;
  }

  .container > .content {
    width: 100%;
    padding: 0 1rem;
  }
}

@media (max-width: 550px) {
  .container {
    padding: 2rem 0rem 7rem;
  }
}
