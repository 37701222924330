.container > div:nth-child(1) {
  display: flex;
  width: 100%;
  padding: 0 2rem;
}

.paraContainer {
  text-align: left;
  flex: 1;
  padding: 0 2rem 0 0;
}

.photoContainer {
  flex: 1;
  padding: 0 0 0 2rem;
  border-left: 1px solid var(--borderSandWhite);
  max-height: 30rem;
}

.photoContainer > img {
  display: block;
  max-width: 30rem;
}

@media (max-width: 1075px) {
  .container > div:nth-child(1) {
    flex-direction: column;
    gap: 3rem;
    align-items: center;
  }

  .photoContainer {
    border-left: unset;
    padding: 0;
    max-height: unset;
  }

  .paraContainer > p {
    max-width: 100%;
    margin-inline: auto;
  }

  .paraContainer {
    padding: 0 1rem;
    text-align: center;
  }

  .container > div:nth-child(1) {
    padding: 0 1rem;
  }
}

@media (max-width: 730px) {
  .paraContainer > p {
    margin-inline: auto;
  }

  .container  {
    padding: 2rem 0 5rem;
  }
  
}

@media (max-width: 550px) {
  .photoContainer > img {
    max-width: 100%;
  }
}
