.productInfo {
  width: 100%;
  text-align: left;
  padding: 1rem 0 0 0;
  bottom: 0;
}

.nameAndPrice {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  text-transform: uppercase;
  letter-spacing: 0.15rem;
}

.nameAndPrice > p {
  opacity: 0.6;
}

.nameAndPrice > p,
.nameAndPrice > h4 {
  font-size: 0.9rem;
  font-weight: 400;
}

.nameAndPrice > p {
  font-size: 0.8rem;
}
