.iconsTablet:before {
  content: "";
  display: block;
  width: calc(100% - 2rem);
  background-color: var(--lightSecondary);
  height: 1px;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  z-index: 9;
}

.iconsTablet ol {
  display: flex;
  list-style-type: none;
  justify-content: center;
  align-items: center;
  gap: 4rem;
  padding: 1rem 0;
  background-color: var(--sandWhite);
  position: relative;
  z-index: 8;
}

.iconsTablet ol li a {
  display: flex;
  gap: 0.3rem;
  align-items: center;
  letter-spacing: 0.1rem;
}
