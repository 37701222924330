.recent {
  z-index: -1;
  border-bottom-left-radius: 0.2rem;
  border-bottom-right-radius: 0.2rem;
  background-color: var(--sandWhite);
  overflow: hidden;
}

.ul {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  overflow: hidden;
}

.ul > li:first-child::before {
  display: inline-block;
  content: "";
  border-top: 1px solid var(--borderSandWhite);
  width: calc(100% - 0.5rem);
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  position: absolute;
}

.ul > li {
  width: 100%;
  position: relative;
}

.ul > li > a > span {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 0.5rem;
  width: 100%;
  padding: 0.45rem 0.7rem 0.45rem 0.1rem;
}

.ul > li > a > span > span:nth-child(2) {
  text-transform: capitalize;
}
