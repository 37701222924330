.container {
  height: 100%;
  display: flex;
  user-select: none;
  position: relative;
  text-transform: none;
}

.container h2 {
  font-weight: 500;
  text-transform: none;
}

.shoppingCartContainer {
  height: 100%;
  display: flex;
  flex-direction: row;
}

.swiper {
  width: 100%;
  flex: 1;
  height: 100%;
}

@media (max-width: 870px) {
  .shoppingCartContainer {
    flex-direction: column;
  }
}
