@import url("https://fonts.cdnfonts.com/css/hollistic");

.sloganContainer {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  text-transform: capitalize;
  min-width: fit-content !important;
  background-color: var(--lightSecondary);
  padding: 2rem 4rem;
  cursor: grab;
  position: relative;
  max-height: 90%;
  background-image: url("https://www.transparenttextures.com/patterns/45-degree-fabric-light.png");
}

.sloganContainer h1 {
  letter-spacing: 0.2rem;
  color: var(--brown);
  text-transform: capitalize;
}

.sloganContainer h5 {
  opacity: 0.8;
  color: var(--brown);
}

.sloganContainer button {
  margin-top: 1.5rem;
}

.sloganContainer_text {
  display: flex;
  flex-direction: column;
  gap: 0.4rem;
}

@media (max-width: 1100px) {
  .sloganContainer {
    flex: 1;
    padding: 2rem 4rem 4rem;
    max-height: 100%;
  }
}

@media (max-width: 640px) {
  .sloganContainer {
    /* flex: 0; */
    justify-content: flex-start;
    text-align: center;
    padding: 1.5rem 0 4rem;
    gap: 2rem;
  }

  .sloganContainer button {
    margin-top: 0;
  }
}
