.container {
  color: var(--brown);
  background-color: var(--lightSecondary);
}

.logo {
  display: flex;
  height: 7rem;
  padding: 1rem 0;
  justify-content: center;
  align-items: center;
  background-color: rgb(241, 236, 235);
  flex-direction: column;
  background-image: url("https://www.transparenttextures.com/patterns/45-degree-fabric-light.png");
}

.logo h1 {
  line-height: 2rem;
}

.logo h5 {
  font-family: "Recoleta 100";
  text-transform: none;
  letter-spacing: 2px;
}

.logo > img {
  display: block;
  max-width: 100%;
  scale: 0.5;
}

.footerInfo {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  position: relative;
  padding: 1rem 0;
  margin-top: 1rem;
}

.footerInfoLeft::after {
  content: "";
  display: block;
  position: absolute;
  border-left: 1px solid var(--brown);
  top: 50%;
  left: 50%;
  transform: translateY(-50%);
  height: 90%;
}

.footerInfo > div {
  flex: 1 1 0px;
  display: flex;
  justify-content: flex-start;
  gap: 2rem;
  padding: 0 2rem;
}

.footerInfoLeft {
  justify-content: flex-end !important;
}

.footerInfoLeft > span {
  overflow: hidden;
}

.footerInfo > div ol > li {
  margin-top: 0.4rem;
  background: linear-gradient(currentColor 0 0) bottom / var(--d, 0) 1px
    no-repeat;
  transition: 0.35s;
  width: fit-content;
}

.footerInfo > div:nth-child(2) > span:first-child ol {
  -ms-user-select: text;
  user-select: text;
}

.footerInfo > div:nth-child(2) > span:nth-child(2) ol {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 0.5rem;
}

/*no transition effect under text on the social icons*/

.container
  > div:nth-child(2)
  > div:nth-child(2)
  > span:nth-child(2)
  ol
  > li:hover,
.container
  > div:nth-child(2)
  > div:nth-child(2)
  > span:first-child
  ol
  > li:hover {
  --d: 0%;
}

.footerInfo li:hover {
  --d: 100%;
}

.copyRight {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1.5rem 0;
  gap: 0.3rem;
}

.container ol > li {
  cursor: pointer;
}

.footerInfo span {
  font-weight: 500;
}

.footerInfo span ol li {
  font-weight: 300;
  font-size: var(--fs-350);
  font-style: italic;
}

.li::first-letter {
  text-transform: capitalize;
}

@media (max-width: 1100px) {
  .footerInfo {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    margin-top: 0;
    padding: 0;
  }

  .footerInfo > div {
    flex-direction: column;
    flex: unset;
    max-width: unset;
    width: 100%;
    gap: unset;
    padding: 0 1rem;
  }

  .footerInfo > div:nth-child(1) > span {
    border-top: 1px solid var(--borderSandWhite);
    padding: 1.5rem 0;
    display: flex;
    flex-direction: column;
  }

  .footerInfo > div:nth-child(1) > span:nth-child(1) {
    border-top: none;
  }


  .footerInfo > div:nth-child(1) > span:nth-child(3) {
    border-bottom: 1px solid var(--borderSandWhite);
  }

  .footerInfo > div:nth-child(1) > span {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: relative;
  }

  .footerInfo > div:nth-child(1) > span > span {
    position: absolute;
    right: 0;
  }

  .footerInfo > div:nth-child(2) {
    align-items: center;
    gap: 1.5rem;
    padding: 1rem 0;
  }

  .footerInfo > div:nth-child(2) > span {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .footerInfo > div:nth-child(2) > span:nth-child(2) > ol {
    gap: unset;
    width: 27vw;
    justify-content: space-around;
  }

  .footerInfo > div:nth-child(1)::after {
    display: none;
  }

  .footerInfo > div:nth-child(2) span:nth-child(1) > ol {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .footerInfo > div:nth-child(2) > span:nth-child(2) ol {
    justify-content: center;
    gap: 1rem;
  }

  .copyRight {
    padding: 0.6rem 2rem 1rem;
    text-align: center;
    display: flex;
    flex-direction: column;
    gap: unset;
    font-size: 0.7rem;
    opacity: 0.6;
  }
}
