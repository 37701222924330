.container {
  border: 2px solid rgba(196, 195, 195, 0.272);
  position: fixed;
  border-radius: 50%;
  width: 3.5rem;
  height: 3.5rem;
  right: 3rem;
  bottom: 3rem;
  z-index: 6;
  background-color: var(--sandWhite);
  -webkit-tap-highlight-color: transparent;
  transition: 0.4s;
}

.container > div {
  border-radius: 50%;
  width: 90%;
  height: 90%;
  border: 0.5px solid var(--brown);
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  cursor: pointer;
}

.container > div > svg {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  color: var(--brown);
}

@media (max-width: 640px) {
  .container {
    width: 3.5rem;
    height: 3.5rem;
    right: 0.6rem;
    bottom: 5rem;
    z-index: 6;
    background-color: var(--sandWhite);
  }
}
