.container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: var(--sandWhite);
  padding: 1rem 3rem 0;
  gap: 1.5rem;
  min-height: 180px;
  text-align: center;
}

.searchContainer {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 800px;
}

.searchContainer > input {
  height: 3rem;
  width: 100%;
  border-radius: 0.5rem;
  padding: 0 3rem 0;
  background-color: var(--sandWhite);
  border: 1px solid var(--borderSandWhite);
}

.searchIcon {
  position: absolute;
  left: 0.8rem;
  font-size: 1.2rem;
}

.splitter {
  padding: 0;
  min-height: unset;
}

@media (max-width: 640px) {
  .container {
    background-color: var(--lightSecondary);
    padding: 0 1rem 0.5rem;
    gap: 1.5rem;
    min-height: unset;
  }

  .splitter {
    display: none;
  }
}
