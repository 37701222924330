.container {
  padding: 3rem 0 7rem;
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.container::before, .container::after {
  display: block; 
  content: "";
  border-top: 0.1rem solid var(--borderSandWhite);
  width: 90%;
  margin-inline: auto; 
  border-radius: 50%;
}

.container > h1 {
 text-align: center;
 color: var(--brown)
}

.gridContainer {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(30%, 240px));
  grid-gap: 15px 15px;
  justify-content: center;
  align-items: baseline;
  padding: 0 2rem;
}

.gridContainer > div {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  gap: 1rem;
  height: 100%;
  border-radius: 0.2rem;
  padding: 1rem;
  text-align: center;
}

.gridContainer > div > h4 {
  font-weight: bold;
  color: var(--brown);
  opacity: 0.7;
}

.gridContainer > div > span {
  color: var(--brown);
  max-width: 30ch;
}

.gridContainer > div > img {
  max-width: 5rem;
}

@media (max-width: 870px) {
  .gridContainer {
    grid-template-columns: repeat(auto-fill, minmax(30%, 160px));
  }
}

@media (max-width: 640px) {
  .gridContainer > div > span {
    font-size: 0.9rem;
  }
}

@media (max-width: 600px) {
  .gridContainer > div > img {
    max-width: 25%;
  }
}

@media (max-width: 580px) {
  .gridContainer {
    grid-template-columns: repeat(auto-fill, minmax(170px, 50%));
  }
}

@media (max-width: 440px) {
  .gridContainer {
    grid-template-columns: repeat(auto-fill, minmax(170px, 70%));
  }
}


