.button,
.disabled {
  display: flex;
  text-transform: uppercase;
  cursor: pointer;
  font-weight: 600;
  letter-spacing: 0.1rem;
  transition: all 0.35s;
  min-width: 12rem;
  width: fit-content;
  height: 3rem;
  position: relative;
  border-radius: 0.2rem;
  font-size: var(--fs-300);
  background-color: var(--brown);
  border: 1px solid transparent;
  outline: none;
  padding: 0.2rem;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.disabled {
  cursor: not-allowed;
}

.button > .borderInside,
.disabled > .borderInside {
  background-color: transparent;
  width: 100%;
  height: 100%;
  border: 1px solid var(--sandWhite);
  border-radius: 0.12rem;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 2rem;
  z-index: 2;
  position: relative;
  color: var(--sandWhite);
}

.button:after {
  position: absolute;
  content: "";
  top: 0;
  left: 0%;
  width: 0;
  height: 100%;
  background-color: var(--sandWhite);
  transition: all 0.35s;
}

.button:hover .buttonName {
  color: var(--brown);
}

.button:hover .borderInside {
  border: 1px solid transparent;
}

.button:hover:after {
  width: 100%;
}

.buttonName {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
  color: var(--sandWhite);
  text-wrap: nowrap;
}

@media (max-width: 1100px) {
  .button , .disabled {
    padding: 0;
  }

  .button > .borderInside,
  .disabled > .borderInside {
    border: 1px solid transparent;
  }

  .button:hover:after {
    width: 0%;
  }

  .button:hover .buttonName {
    color: unset;
  }

  .button:hover {
    background-color: rgb(55, 74, 94);
  }
}

@media (max-width: 640px) {
  .button,
  .disabled {
    font-size: var(--fs-200);
    height: 3rem !important;
  }
}
