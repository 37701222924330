.container {
  position: relative;
  color: var(--brown);
  height: 70vh;
  height: 70svh;
  overflow: hidden;
}

.container > img {
  display: block;
  min-width: 100%;
  object-fit: cover !important;
  height: 100%;
}

.container > div {
  background-color: rgba(230, 224, 222, 0.452);
  position: absolute;
  z-index: 1;
  width: 100%;
  height: 100%;
}

.container > a {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
  height: fit-content;
  width: 20rem;
  cursor: pointer;
}

.container > a > h3 {
  text-align: center;
  padding: 1rem 0;
}

.wrapper {
  display: flex;
  flex-direction: column;
  border-radius: var(--borderMenuRadius);
  overflow: hidden;
  cursor: pointer;
}

.wrapper > div:nth-child(1) {
  flex: 2;
  overflow: hidden;
}

.wrapper > div:nth-child(1) > img {
  display: block;
  width: 100%;
  min-height: 100%;
  transition: all 0.3s;
  object-fit: cover;
  scale: 1.05
}

.wrapper > div:nth-child(2) {
  flex: 1;
  background-color: var(--brown);
  display: flex;
  flex-direction: column;
  padding: 1.5rem;
  gap: 0.3rem;
  color: var(--sandWhite);
  background-color: var(--brown);
background-image: url("https://www.transparenttextures.com/patterns/binding-dark.png");
/* This is mostly intended for prototyping; please download the pattern and re-host for production environments. Thank you! */
}

.wrapper > div:nth-child(2) > span:not(:nth-child(1), :nth-child(2)) {
  margin-top: auto;
  display: flex;
  flex-direction: column;
}

.wrapper > div:nth-child(1) > img:hover {
  transform: scale(1.03);
}

@media (max-width: 640px) {
  .container {
    height: 90svh;
  }

  .container > a {
    width: 80%;
  }

  .container > a > div:nth-child(2) {
    padding: 0.8rem;
  }
}

@media (max-height: 565px) {
  .container {
    height: 110svh;
  }
}

@media only screen and (max-height: 575.98px) and (orientation: landscape) {
  .container {
    height: 40rem;
  }
}
