@import url('https://fonts.cdnfonts.com/css/angelyn');

.container {
  background-color: var(--lightSecondary);
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  padding: 0 0 5rem;
}

.container > span {
  text-align: center;
  position: absolute;
  bottom: 1rem;
}

.container > span > h1 {
  color: var(--brown);
  text-transform: none;
  opacity: 0.8;
}

.container > span > h1::first-letter {
  text-transform: capitalize;
}

.container > img {
  max-width: 100%;
  object-fit: stretch;
  height: auto;
  max-height: 100%;
  flex-shrink: 0
}

@media (max-width: 640px) {
  .container {
    margin-top: 0rem;
    padding: 6.5rem 0 5rem;
    min-height: 293px;
  }

  .container > img {
    scale: 2;
    transform: translate(0, -10%);
  }

  .container > span {
    width: 85%;
  }

}

@media (max-width: 430px) {
  .container {
    padding: 4rem 0 0rem;
    min-height: 243px;
  }

  .container > img {
    scale: 2;
    transform: translate(0, 0%);
  }

  .container > span {
    color: var(--brown);
    text-align: center;
  }
}

@media (max-width: 320px) {
  .container {
    padding: 3rem 0 0rem;
    min-height: 203px;
  }
}
