.form {
  flex: 2 1 0px;
  padding: 1rem 1rem;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  text-align: left;
}

.duo {
  display: flex;
  gap: 1rem;
}

.duo > span {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 0.5rem;
  flex: 1 1 0px;
}

.form input {
  border: 1px solid var(--borderSandWhite);
  border-radius: 0.2rem;
  padding: 1rem;
  background-color: var(--sandWhite);
  color: rgb(177, 177, 177);
}

.form label {
  font-weight: 500;
}

.duo > span > input {
  width: 100%;
  height: 3rem;
}

.form > span:not(:nth-child(1), :nth-child(2)) {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  align-items: flex-start;
}

.subject {
  flex: 1 1 0px;
}

.subject input {
  width: 100%;
  height: 3rem;
}

.message {
  max-width: 35.2rem;
  display: flex;
  position: relative;
}

.message > span {
  margin-top: 2rem;
}

.textArea textarea {
  min-height: 10rem;
  max-width: 100%;
  width: 100%;
  border: 1px solid var(--borderSandWhite);
  border-radius: 0.2rem;
  padding: 1rem;
  background-color: var(--sandWhite);
  color: rgb(177, 177, 177);
}

.form label {
  position: relative;
}

.form label:after {
  content: "*";
  position: absolute;
  right: -0.7rem;
  top: 0;
  color: red;
}

.form .phone label:after {
  content: unset;
}

.message > button {
  width: 100%;
  margin-top: 2rem;
}

.textArea {
  position: relative;
  width: 100%;
}

.controlErrorMessage {
  color: rgb(225, 129, 116);
  font-size: 0.9rem;
  margin-top: -0.2rem;
  position: absolute;
  bottom: -1rem;
}

@media (max-width: 1100px) {
  .form {
    padding: unset;
  }

  .message {
    max-width: unset;
    width: 100%;
  }
}

@media (max-width: 640px) {
  .container {
    width: 100%;
  }

  .message > span {
    width: 100%;
    /* display: flex; */
    /* justify-content: center; */
  }

  .message > button {
    width: 100%;
  }

  .duo {
    flex-direction: column;
    gap: 2rem;
  }
}
