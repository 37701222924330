.container {
  color: var(--brown);
  z-index: 1;
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 0.5rem 0 0;
}

.container > div {
  height: 3rem;
  width: 3rem;
}

.container > span {
  text-wrap: nowrap;
}
