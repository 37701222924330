.bannerSwiper {
  position: relative;
}

.swiperSlides {
  display: flex;
  flex-direction: row !important;
  max-height: calc(100vh - 6rem)!important;
  aspect-ratio: 16/9;
  background-color: var(--sandWhite);
}

.swiperSlides > div:nth-child(1) {
  flex: 3;
  display: flex;
  cursor: grab;
  aspect-ratio: 16/9;
  overflow: hidden;
  max-height: 90%;
}

.swiperSlides > div:nth-child(1) > img {
  display: block;
  scroll-snap-align: start;
  object-fit: cover;
  width: 100%;
  height: auto;
}

.bannerSwiper > div:nth-child(2) {
  position: absolute;
  margin-left: auto;
  margin-right: auto;
  left: 50% !important;
  transform: translateX(-50%) !important;
  bottom: 1.7rem !important;
}

.bannerSwiper > div:nth-child(4) {
  position: absolute;
  margin-left: auto;
  margin-right: auto;
  right: 0!important;
  width: fit-content !important;
}

@media (max-width: 1100px) {
  .swiperSlides {
    flex-direction: column !important;
    aspect-ratio: unset;
  }
}

@media (max-width: 640px) {
  .swiperSlides {
    max-height: unset;
    height: calc(100svh - 7.8rem);
    padding: 0;
    background-color: var(--sandWhite);
  }

  .controls > button {
    display: none;
  }
}

