.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: var(--brown);
  background-color: var(--lightSecondary);
}

.container ol {
  min-height: 5rem;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 0 2.5rem;
  gap: 2rem;
}

.backHome {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  cursor: pointer;
  padding: 0.41rem 0.8rem;
  color: var(--brown);
  border-radius: 3rem;
  background-color: transparent;
  position: relative;
  border: 1px solid var(--brown);
}

.backHome > span {
  text-transform: capitalize;
}

.pathNames > li:first-child span:nth-child(2),
.pathNames > li:last-child span:nth-child(2) {
  display: none;
}

.pathNames {
  display: flex;
  gap: 1rem;
  color: var(--brown)
}

.pathNames > li {
  display: flex;
  gap: 1rem;
  justify-content: center;
  align-items: center;
  position: relative;
}

.pathNames > li:last-child::after {
  content: "";
  border-bottom: 1px solid var(--brown);
  width: 100%;
  display: block;
  position: absolute;
  bottom: -0.2rem;
}

.backHome:hover {
  background-color: var(--brown);
  color: var(--sandWhite);
  border: 1px solid var(--brown);
}

.back div:hover svg {
  color: var(--sandWhite);
}

.back div:hover {
  background-color: var(--brown);
  border: 1px solid var(--brown);
}

.back {
  display: flex;
  gap: 0rem;
  justify-content: center;
  align-items: center;
}

.back > div {
  background-color: transparent;
  border-radius: 50%;
  width: 2.3rem;
  height: 2.3rem;
  position: relative;
  transition: all 0.35s;
  overflow: hidden;
  cursor: pointer;
  border: 1px solid transparent;
  border: 1px solid var(--brown);
}

.back > div > svg {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: var(--sandWhite);
  z-index: 2;
  color: var(--brown);
}


@media (max-width: 1100px) {
  .container ol {
    padding: 0 2rem;
  }
}

@media (max-width: 640px) {
  .container {
    display: none;
  }
}
