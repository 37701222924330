.mobileHeader {
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 6;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.7rem 1rem;
  color: var(--brown);
  transition: 0.4s;
  /* background-color: rgba(255, 255, 255, 0.778); */
  height: 3.2rem;
  /* border-bottom: 1px solid var(--borderSandWhite); */
  background-color: var(--lightSecondary);
}

.mobileHeader > div {
  flex: 1 1 0px;
  display: flex;
  justify-content: flex-end;
  text-align: center;
  z-index: 3;
}

.mobileHeader > div:nth-child(1) {
  justify-content: flex-start;
}

.mobileHeader > div:nth-child(2) {
  justify-content: center;
  margin: auto;
  align-items: center;
  flex: 4 1 0px;
}

.mobileHeader > div:nth-child(1),
.mobileHeader > div:nth-child(3) {
  display: flex;
  align-self: center;
}
