.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 3rem;
  color: var(--brown);
  padding: 4rem 1rem;
}

.container > div:nth-child(1) {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1.5rem;
  width: 100%;
}

.container > div:nth-child(1) > div {
  display: flex;
  flex-direction: column;
  text-align: center;
}

.container > div:nth-child(2) > span {
  font-family: Minerva Modern;
  font-weight: normal;
  font-size: var(--fs-400);
}

.container > div:nth-child(2) {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
}

.container > div:nth-child(2) ol {
  display: flex;
  justify-content: center;
  align-items: center;
}

.container > div:nth-child(2) ol li:not(:first-child) {
  margin-left: 1rem;
}

@media (max-width: 640px) {
  .container {
    padding: 4rem 1.5rem;
  }

  .container button {
    width: 100%;
  }
}

@media (max-width: 380px) {
  .container {
    padding: 2rem 1.5rem;
  }
}
