.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 2rem;
}

.pageInfo {
  background-color: var(--lightSecondary);
}

.container > div {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.productContainer {
  display: flex;
  flex-direction: column;
  text-align: center;
}

.gridContainer {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(30%, 1fr));
  column-gap: 2rem;
  row-gap: 4rem;
  padding: 0 1rem 3rem;
}

.productItem {
  background-color: var(--lightSecondary);
  padding: 20px;
  border-radius: var(--borderMenuRadius);
  text-align: center;
}

.splitter {
  padding: 0;
  min-height: unset;
}

@media (max-width: 1344px) {
  .gridContainer {
    grid-template-columns: repeat(auto-fill, minmax(324px, 1fr));
  }
}

@media (max-width: 640px) {
  .container {
    gap: 2rem;
    padding: 2rem 1rem 0;
  }

  .splitter {
    display: none;
  }

  .gridContainer {
    grid-template-columns: repeat(auto-fill, minmax(100%, 1fr));
  }
}
