.wrapper {
  display: none;
}

@media (max-width: 1100px) {
  .wrapper {
    position: absolute;
    left: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 0;
    width: 100%;
    height: 100%;
    background-color: var(--sandWhite);
    overscroll-behavior-y: contain;
  }

  .suggestionsWrapper {
    position: absolute;
    overscroll-behavior-y: contain;
    background-color: var(--sandWhite);
    width: calc(100% - 2rem);
    top: 5.5rem;
    left: 50%;
    transform: translate(-50%, 0rem);
    padding: 0 1rem 0 0;
    overflow: auto;
    max-height: 75%;
    border: 1px solid var(--borderSandWhite);
    border-radius: 0.2rem;
    z-index: 7;
  }

  .suggestions {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    border-bottom-left-radius: 0.2rem;
    border-bottom-right-radius: 0.2rem;
  }

  .suggestions > li {
    overflow: hidden;
    width: 100%;
  }

  .suggestions > li > a > span {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 0.5rem;
    width: 100%;
    padding: 0.45rem 0.7rem 0.45rem 0.2rem;
  }

  .suggestions > li {
    position: relative;
  }

  .searchStyle {
    min-height: unset !important;
    margin-top: 5rem;
  }

  .errorWrapper {
    padding: 0 2rem;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .errorWrapper > div {
    margin-top: auto;
    height: 100%;
  }
}

@media (max-width: 640px) {
  .wrapper {
    max-height: calc(100svh - 55px);
  }

  .errorWrapper > div {
    margin-top: auto;
    height: calc(100% - 5rem);
  }
}
