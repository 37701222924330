.shoppingCart {
  display: flex;
  flex: 5;
  gap: 1rem;
  padding: 4rem 2rem;
  flex-direction: column;
  height: 100%;
  max-height: 100%;
  overflow: auto;
}

.shoppingCartHeader {
  display: flex;
  justify-content: space-between;
}

.shoppingCartWrapper {
  border-top: 1.5px solid var(--transparentDarkBlue);
  padding: 1rem 0;
}

.shoppingCartWrapper > ol {
  display: flex;
  flex-direction: column;
  gap: 0.3rem;
  width: 100%;
}

.shoppingCartWrapper > ol > li {
  display: grid;
  padding: 0.5rem 1rem 0.5rem 0rem;
  grid-template-columns: repeat(6, 1fr) auto;
  grid-template-rows: 1fr;
  grid-column-gap: 0px;
  grid-row-gap: 0px;
}

.shoppingCartWrapper > ol > li > div {
  display: flex;
  justify-content: center;
  align-items: center;
}

.shoppingCartWrapper > ol > li > div:nth-child(1) {
  grid-area: 1 / 1 / 2 / 2;
  justify-content: flex-start;
}

.shoppingCartWrapper > ol > li > div:nth-child(2) {
  grid-area: 1 / 2 / 2 / 4;
  flex-direction: column;
  align-items: flex-start;
}

.shoppingCartWrapper > ol > li > div:nth-child(3) {
  grid-area: 1 / 4 / 2 / 5;
  display: flex;
}

.shoppingCartWrapper > ol > li > div:nth-child(4) {
  grid-area: 1 / 5 / 2 / 6;
}

.shoppingCartWrapper > ol > li > div:nth-child(5) {
  grid-area: 1 / 6 / 2 / 6;
}

.shoppingCartWrapper > ol > li > div:nth-child(6) {
  grid-area: 1 / 7 / 2 / 7;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  
}

.shoppingCartItemsHeader > li > div:nth-child(1) {
  grid-area: 1 / 3 / 2 / 1 !important;
}

.shoppingCartItemsHeader {
  text-transform: uppercase;
}

.shoppingCartItems {
  all: unset;
  height: 100%;
}

.shoppingCartItems > li {
  border-bottom: 1px solid var(--transparentDarkBlue);
}

@media (max-width: 1260px) {
  .shoppingCart {
    padding: 4rem 2rem;
  }

  .shoppingCartWrapper {
    padding: 1rem 0;
  }

  .shoppingCartWrapper > ol {
    gap: 0.6rem;
    width: 100%;
  }

  .shoppingCartWrapper > ol > li {
    padding: 0;
    grid-template-columns: repeat(5, 1fr) auto;
    grid-template-rows: repeat(1.5, 1fr);
    border: 1px solid rgba(33, 44, 56, 0.084);
    border-radius: 0.2rem;
  }

  .shoppingCartWrapper > ol > li > div:nth-child(1) {
    grid-area: 1 / 1 / 3 / 2;
    justify-content: flex-start;
  }

  .shoppingCartWrapper > ol > li > div:nth-child(2) {
    grid-area: 1 / 2 / 1 / 5;
    flex-direction: column;
    align-items: flex-start;
    padding: 0 0 0 0.5rem;
  }

  .shoppingCartWrapper > ol > li > div:nth-child(3) {
    grid-area: 2 / 2 / 2 / 2;
    display: flex;
    height: fit-content;
  }

  .shoppingCartWrapper > ol > li > div:nth-child(4) {
    grid-area: unset;
    display: none;
  }

  .shoppingCartWrapper > ol > li > div:nth-child(5) {
    grid-area: 2 / 3 / 2 / 6;
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    padding: 0 0.5rem 0.5rem;
  }

  .shoppingCartWrapper > ol > li > div:nth-child(6) {
    grid-area: 1 / 5 / 2 / 5;
    display: flex;
    justify-content: flex-end;
    align-items: flex-start;
    padding: 0.3rem;
  }

  .shoppingCartItemsHeader {
    display: none !important;
  }

  .shoppingCartItems > li {
    border-bottom: unset;
  }
}

@media (max-width: 640px) {
  .shoppingCart {
    gap: 1rem;
    padding: 0 1rem;
  }
}
