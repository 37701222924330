.imgContainer {
  width: 6.5rem;
  height: 6.5rem;
}

.imgContainer > img {
  width: 100%;
  height: 100%;
  display: block;
  object-fit: cover;
}

.productDetail {
  display: flex;
  gap: 0.4rem;
  text-transform: none;
}

.productDetail > p {
  color: rgb(198, 188, 186);
}

.price {
  display: none;
}

.priceContainer,
.price,
.totalUnitPrice {
  text-transform: lowercase;
}

.quantityContainer {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: center;
  gap: 0.5rem;
  width: 6.5rem;
  user-select: none;
}

.quantityWrapper {
  border: 1px solid var(--transparentDarkBlue);
  display: flex;
  border-radius: 0.2rem;
  width: 100%;
}

.quantityWrapper > div {
  padding: 0.5rem;
  flex: 1 1 0px;
  height: 43px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.quantityWrapper > div:first-child {
  cursor: pointer;
}

.quantityWrapper > div:nth-child(2) {
  width: 40px;
}

.quantityWrapper > div:last-child {
  border-right: unset;
  cursor: pointer;
}

.trash {
  margin: 0.2rem
}

.trash svg {
  border-radius: 50%;
  border: 1px solid var(--transparentDarkBlue);
  padding: 0.5rem;
  cursor: pointer;
  transform: rotate(45deg);
}

.trash svg:hover {
  background-color: var(--secondary);
  border: 1px solid transparent;
}

@media (max-width: 1260px) {
  .imgContainer {
    width: 8rem;
    height: 112px;
  }

  .productDetail {
    gap: 0.1rem;
    font-size: 0.8rem;
  }

  .productDetail h4 {
    text-transform: none;
  }

  .price {
    display: flex;
  }

  .trash svg {
    font-size: 1.5rem;
    padding: 0.3rem;
  }

  .trash svg:hover {
    background-color: transparent;
    border: 1px solid transparent;
  }

  .quantityWrapper {
    border: 1px solid transparent;
  }

  .quantityContainer {
    margin-right: auto;
  }

  .quantityWrapper > div {
    padding: 0.5rem;
    height: 35px;
  }

  .quantityWrapper > div:first-child {
    border: 1px solid var(--transparentDarkBlue);
    border-radius: 50%;
    scale: 0.8;
    width: 30px;
  }

  .quantityWrapper > div:nth-child(2) {
    width: 40px;
  }

  .quantityWrapper > div:last-child {
    border: 1px solid var(--transparentDarkBlue);
    border-radius: 50%;
    scale: 0.8;
    width: 30px;
  }
}

@media (max-width: 640px) {
  .imgContainer {
    width: 7rem;
    height: 112px;
  }
}
