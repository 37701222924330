.ul {
  display: flex;
  flex-direction: column;
}

.ul li {
  text-transform: capitalize;
  cursor: pointer;
  padding: 0.7rem 0.5rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;
}

.ul li:hover {
  background-color: var(--secondary);
}

.container {
  display: flex;
  flex-direction: row;
  gap: 0.3rem;
  align-items: center;
}

.container > span:nth-child(1) {
  width: 1.5rem;
  display: flex;
}

.container > img {
  max-width: 100%;
  object-fit: cover;
}

