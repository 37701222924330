.container {
  min-height: 6rem;
  padding: 0 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  position: fixed;
  z-index: 10;
  min-width: 370.46px;
  flex-direction: column;
  gap: 0.5rem;
  top: 0;
}

.container > span:nth-child(1) > h4 {
  color: var(--sandWhite);
  font-weight: 400;
  text-transform: none;
  z-index: 0;
}

.container > span:nth-child(1) {
  width: 100%;
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.container > span:nth-child(2) > svg {
  transition: all 0.33s;
}

.container > span:nth-child(2) > svg:hover {
  scale: 1.1;
}

.iconWrapper {
  position: relative;
  width: 2.5rem;
  height: 2.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.iconWrapper > span:nth-child(2) {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 50%;
  padding: 0.3rem;
  width: 3rem;
  height: 3rem;
}

.iconWrapper > span:nth-child(1) {
  z-index: 1;
}

.countdownLine {
  width: 100%;
  height: 5px;
  background-color: var(--brown);
  position: relative;
}

.countdownLine::before {
  content: "";
  display: block;
  width: 100%;
  height: 100%;
  background-color: var(--sandWhite);
  position: absolute;
  top: 0;
  left: 0;
  animation: countdown 5s linear forwards;
}

@keyframes countdown {
  0% { width: 100%; }
  100% { width: 0; }
}

@media (max-width: 640px) {
  .container {
    border-bottom-left-radius: unset;
    min-width: unset;
    min-height: unset;
    padding: 1rem;
    justify-content: space-between;
    width: calc(100% - 1rem);
    min-width: none;
    right: 0 !important;
  }
}
