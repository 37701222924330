.suggestionsContainer {
  position: absolute;
  width: 100%;
  z-index: 1;
  top: 88%;
  background-color: var(--sandWhite);
  display: flex;
  flex-direction: column;
  border-bottom-right-radius: 0.5rem;
  border-bottom-left-radius: 0.5rem;
  border-bottom: 1px solid var(--borderSandWhite);
  border-left: 1px solid var(--borderSandWhite);
  border-right: 1px solid var(--borderSandWhite);
  overflow: hidden;
  text-align:left;
}

.suggestionsContainer > div:nth-child(1) {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.topResults {
  display: flex;
  flex-direction: column;
  gap: 1.3rem;
  padding: 1rem 1rem 0rem;
  position: relative;
}

.topSuggestions {
  display: flex;
  flex-direction: column;
  gap: 0.4rem;
}

.topSuggestions > ul {
  display: flex;
  gap: 0.4rem;
}

.topSuggestions > ul > li {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.3rem 0.7rem;
  border-radius: 1rem;
  background-color: var(--lightSecondary);
  cursor: pointer;
}

.topSuggestions > ul > li > p {
  font-size: 0.9rem;
}

.topSuggestions > p {
  opacity: 0.5;
}

.topSuggestions > h4 {
  text-transform: capitalize;
  font-weight: 400;
  font-size: var(--fs-400);
}

.suggestionsContainer > div:nth-child(1) {
  padding: 0.5rem;
}

.suggestionsResults {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  max-height: 40vh;
  overflow: auto;
}

/* width */
.suggestionsContainer ::-webkit-scrollbar {
  width: 5px;
}

/* Handle */
.suggestionsContainer ::-webkit-scrollbar-thumb {
  background: rgba(33, 44, 56, 0.622);
  border-radius: 1rem;
}

.categoryContainer {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 0.5rem;
  position: relative;
}

.categoryWrapper {
  padding: 0.5rem 1rem;
}

.suggestionsResults > div:not(:last-child) {
  border-bottom: 1px solid var(--borderSandWhite);
}

.categoryContainer > span {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--brown);
  border-radius: 0.2rem;
  color: var(--sandWhite);
  padding: 0.2rem;
  min-width: 1.6rem;
}

.ul {
  display: flex;
  gap: 0.5rem;
  flex-direction: column;
  overflow: hidden;
}

.ul > li {
  position: relative;
}

.borderBottom {
  border-bottom: 1px solid var(--borderSandWhite);
  padding: 1rem;
}


.suggestionsContainer::before {
  display: inline-block;
  content: "";
  border-top: 1px solid var(--borderSandWhite);
  width: calc(100% - 1.1rem);
  top: 0.2rem;
  left: 50%;
  transform: translateX(-50%);
  position: absolute;
}

.ul > li > span {
  display: flex;
  gap: 0.3rem;
  cursor: pointer;
}

.ul > li:first-child {
  padding-top: 0.5rem;
}

.ul > li:last-child {
  padding-bottom: 0.5rem;
}

@media (max-width: 640px) {
  .suggestionsResults {
    max-height: 30vh;
  }
}
