.wrapper {
  max-width: 1600px;
  position: relative;
  left: 50%;
  transform: translate(-50%);
  padding: 0 0 7rem;
}

@media (max-width: 640px) {
  .wrapper {
    padding: 0 0 4rem;
  }
}

@media (max-width: 380px) {
  .wrapper {
    padding: 0 0 2rem;
  }
}
