.container {
  background-color: var(--sandWhite);
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  gap: 2rem;
  flex-direction: column;
  padding: 2rem 3rem;
  min-height: 180px;
}

.splitter {
  padding: 0;
  min-height: unset;
}

.navigate {
  max-width: 100%;
}

.slides {
  height: 3rem;
  padding: 0.2rem 1.7rem;
}

.slides > h6 {
  font-size: 1rem !important;
}

@media (max-width: 1100px) {
  .container {
    padding: 2rem 0;
  }
  .slider {
    padding: 0 1rem;
  }
}

@media (max-width: 640px) {
  .container {
    background-color: var(--lightSecondary);
    padding: 0;
    min-height: unset;
  }

  .slides {
    height: 2.5rem;
    padding: 0.2rem 1.7rem;
  }

  .slides > h6 {
    font-size: 0.9rem !important;
  }

  .splitter {
    display: none;
  }

  .navigate {
    padding: 1rem 0 0.5rem;
  }
}
