.orderSummary {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding: 4rem 2rem 2rem;
  flex-direction: column;
  background-color: var(--secondary);
  gap: 1rem;
  flex: 1.5;
}

.orderSummaryWrapper {
  border-top: 1.5px solid var(--transparentDarkBlue);
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.buttonWrapper {
  display: flex;
  gap: 0.5rem;
  flex-direction: column;
}

.buttonWrapper button {
  width: 100%;
}

.totalCost {
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin-top: auto;
  border-top: 1.5px solid var(--transparentDarkBlue);
  padding: 1rem 0;
  font-weight: 500;
  text-transform: none;
}

@media (max-width: 1160px) {
  .orderSummary {
    flex: 2;
  }
}

@media (max-width: 970px) {
  .orderSummary {
    flex: 2.5;
  }
}

/* @media (max-width: 860px) {
  
} */

@media (max-width: 870px) {
  .orderSummaryWrapper > button {
    width: 100%;
  }

  .totalCost {
    padding: 0;
  }

  .totalCost span:nth-child(2) {
    margin-right: 0.4rem;
  }

  .orderSummary {
    align-items: center;
    padding: 1rem;
    flex: 0;
    background-color: var(--sandWhite);
    border-top: 1px solid var(--borderSandWhite);
  }

  .orderSummaryWrapper {
    height: unset;
    min-width: unset;
    width: 100%;
    gap: 1rem;
  }

  .buttonWrapper {
    flex-direction: row;
  }

  .buttonWrapper > :nth-child(1) {
    min-width: unset;
  }

  .orderSummaryWrapper,
  .totalCost {
    border-top: unset;
  }

  .orderSummary h2 {
    display: none;
  }

  .backButton button {
    width: unset !important;
    min-width: unset !important;
  }
}
