.searchBoxContainer {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 2rem 1rem 0;
  position: relative;
  z-index: 7;
}

.searchBoxContainer > section {
  overflow: hidden;
  background-color: var(--sandWhite);
  border-radius: 0.2rem;
  position: relative;
  width: 100%;
  border: 1px solid var(--borderSandWhite);
}

.searchBoxContainer > section > input {
  border: none;
  padding: 1.5rem 0.7rem 1.5rem 3rem;
  color: var(--brown);
  background-color: transparent;
  width: 100%;
  position: relative;
}

.searchBoxContainer > section > input[type="search"] {
  font-size: 1rem;
  height: 2.5rem;
}

.searchBoxContainer > section > span {
  position: absolute;
  left: 0.4rem;
  top: 50%;
  transform: translateY(-53%);
  z-index: 10;
}

.searchBoxContainer > section > span > svg {
  margin-top: 0.3rem;
  margin-left: 0  !important;
}

.searchBoxContainer > section > button {
  all: unset;
  position: absolute;
  text-align: center;
  width: fit-content;
  text-transform: capitalize;
  font-size: 0.8rem;
  font-weight: 500;
  padding: 0 1rem;
  color: var(--brown);
  cursor: pointer;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  height: 100%;
}
