.routeSwiper {
  padding: 0 0 4rem !important;
  margin-left: -12rem !important;
  margin-right: -12rem !important;
}

.routeSwiper > div:nth-child(2) {
  position: absolute;
  margin-left: auto;
  margin-right: auto;
  left: 50% !important;
  transform: translateX(-50%) !important;
  bottom: 0rem !important;
}

.routeSlides {
  border-radius: 0.2rem;
  overflow: hidden;
  color: var(--brown);
  min-height: 43vh;
  max-height: 48vh;
  background-color: var(--lightSecondary);
  cursor: pointer;
}

.routeInfo {
  text-align: center;
  position: absolute;
  bottom: 2rem;
  left: 50%;
  transform: translateX(-50%);
  font-weight: normal;
  text-transform: uppercase;
  font-size: var(--fs-400);
  width: 100%;
}

.routeImgContainer {
  overflow: hidden;
  position: absolute;
  top: 15%;
}

.routeImgContainer img {
  max-width: 100%;
  aspect-ratio: 2.5/2;
  object-fit: contain;
}

@media (max-width: 640px) {
  .routeSlides {
    min-height: 405px;
  }
}

@media only screen and (max-height: 575.98px) and (orientation: landscape) {
  .routeSlides {
    min-height: 23rem;
  }
}
