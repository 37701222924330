.iconStyles  {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  border-radius: 50%;
  width: 1.6rem;
  height: 1.6rem;
}



