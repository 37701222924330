.openSearch {
  display: flex;
  align-items: center;
  height: 100%;
  width: 100%;
  padding: 0 1rem 0 2.7rem;
}

.openSearch input {
  background-color: transparent;
  outline: none;
  border: none;
  height: 100%;
  width: 100%;
}

.openSearch > input::placeholder,
.openSearch > input::-webkit-input-placeholder {
  position: absolute !important;
  top: 50% !important;
  transform: translateY(-50%) !important;
}
