.search {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  justify-content: center;
  align-items: center;
}

.search > div {
  position: relative;
  width: 505px;
}

.glass {
  position: absolute;
  top: 55%;
  left: 56.5%;
  transform: translate(-56.5%, -55%);
  z-index: 1;
}

.glass > img {
  max-width: 100%;
  scale: 0.5;
}

@media (max-width: 640px) {
  .search {
    min-height: calc(100svh - 245px);
  }

  .search > div {
    position: relative;
    width: 90%;
  }

  .glass {
    position: absolute;
    top: 55%;
    left: 56.5%;
    transform: translate(-56.5%, -55%);
    z-index: 1;
  }
}
