.searchButtonContainer {
  min-height: 2.5rem;
  display: flex;
  position: relative;
  background-color: var(--sandWhite);
  color: var(--brown);
  border-radius: 0.2rem;
  z-index: 8;
  flex-direction: column;
  margin-top: auto;
  border: 1px solid var(--borderSandWhite);
  align-items: center;
  justify-content: center;
}

.searchButtonAndInputWrapper {
  width: 100%;
  height: 2.5rem;
}

.icon {
  font-size: 1.8rem;
  position: absolute;
  height: 100%;
  left: 0;
  top: 0;
  max-height: 2.5rem;
}

.icon svg {
  margin-bottom: 0.4rem;
}
