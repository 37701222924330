.imgContainer {
  position: relative;
  overflow: hidden;
  transition: 0.35s;
  aspect-ratio: 3/3;
  display: flex;
  border-radius: 0.2rem;
}

.imgContainer > img {
  width: 100%;
  display: block;
  object-fit: cover;
  aspect-ratio: 3/3;
  transition: 0.35s;
}








