.overlay {
  position: fixed;
  z-index: 6;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: rgba(63, 62, 62, 0.668);
}

