.container {
  width: 50rem;
}

.container > li {
  display: flex;
  border-top: 1px solid var(--borderSandWhite);
  cursor: pointer;
  flex-direction: column;
  text-align: left;
  overflow: hidden;
  position: relative;
  justify-content: center;
  padding: 1rem 0;
}

.container > li:last-child {
  border-bottom: 1px solid var(--borderSandWhite);
}

.container > li > div:nth-child(1) {
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 22;
}

.container > li > div > span:nth-child(2) {
  border: 1px solid rgba(194, 194, 194, 0.198);
  border-radius: 50%;
  width: 2rem;
  min-width: 2rem;
  height: 2rem;
  position: relative;
  background-color: var(--lightSecondary);
  display: flex;
  justify-content: center;
  align-items: center;
}

.container > li > div > div {
  padding: 1rem 2rem 1rem 0;
}

.para {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.para > span {
  display: flex;
}

.container > li > span > h6 {
  font-weight: 500;
}

@media (max-width: 1100px) {
  .container {
    width: 85%;
  }
}

@media (max-width: 640px) {
  .container {
    width: 100%;
  }
}
