.productSwiper {
  height: fit-content !important;
}

.productSlides {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  border-radius: 0.2rem;
  overflow: hidden;
  color: var(--brown);
  width: 520px;
  height: 100% !important;
}

.productSwiper > div:nth-child(2) {
  position: absolute;
  margin-left: auto;
  margin-right: auto;
  left: 50% !important;
  transform: translateX(-50%) !important;
  bottom: 0 !important;
  display: none !important;
}

@media (max-width: 1600px) {
  .productSwiper {
    padding: 0 1rem 4rem;
  }

  .productSwiper > div:nth-child(2) {
    display: block !important;
  }
}

@media (max-width: 640px) {
  .productSwiper > div:nth-child(2) {
    width: unset !important;
    width: 90vw !important;
  }
}
