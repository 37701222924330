.header_hamburger {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  gap: 1rem;
}

.list {
  background: linear-gradient(currentColor 0 0) bottom / var(--d, 0) 1px
    no-repeat;
  transition: 0.35s;
}

.list:hover {
  --d: 100%;
}

@media (max-width: 640px) {
  .header_hamburger {
    flex-direction: column;
  }
}
