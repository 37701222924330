.container {
  display: flex;
  padding: 7rem 1rem 0;
  gap: 5rem;
  justify-content: space-between;
  align-items: center;
  color: var(--brown);
}

.container > div {
  flex: 1 0 0px;
}

.container__text {
  display: flex;
  flex-direction: column;
  gap: 4rem;
  align-self: center;
}

.container__text > div {
  overflow: hidden;
}

.container__text > button {
  margin-top: -1.5rem;
}

.container__photo {
  border-radius: 0.2rem;
  overflow: hidden;
  position: relative;
}

.container__photo img {
  max-width: 100%;
  aspect-ratio: 4/2.7;
  object-fit: cover;
  border-radius: 0.2rem;
}

.container__photo > span {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 200;
  background-color: var(--lightSecondary);
}

.container > section {
  position: absolute;
  top: 20%;
  width: 100%;
  height: 0.1rem;
}

@media (max-width: 900px) {
  .container {
    padding: 7rem 3rem 0;
  }

  .container__text {
    gap: 2rem;
    align-items: center;
    text-align: center;
  }

  .container__text img {
    max-width: 80%;
    min-height: 30rem;
    aspect-ratio: 3/2.7;
    object-fit: cover;
  }

  .container__text > button {
    margin-top: unset;
  }
}


@media (max-width: 640px) {
  .container {
    padding: 4rem 1.5rem 0;
  }

  .container__text img {
    max-width: 85%;
    min-height: 20rem;
  }

  .container button {
    width: 100%;
  }
}

@media (max-width: 380px) {
  .container {
    padding: 2rem 2rem 0;
  }

  .container__text img {
    max-width: 90%;
    min-height: 17rem;
  }
}
