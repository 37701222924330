.container {
  display: flex;
  gap: 2rem;
  user-select: none;
  color: var(--brown);
  padding: 0 0 4rem;
  justify-content: center;
}

.imgContainer {
  flex: 1;
  flex-shrink: none;
  padding: 1rem 0 0 1rem;
  display: flex;
  gap: 1rem;
  margin-bottom: auto;
}

.imgWrapper {
  width: 40rem;
  height: 40rem;
}

.albumContainer > ol {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.albumContainer > ol > li {
  width: 90px;
  border: 1px solid var(--borderSandWhite);
  padding: 0.2rem;
}

.albumContainer > ol > li > img {
  max-width: 100%;
  object-fit: cover;
  display: block;
  cursor: pointer;
  aspect-ratio: 4/4;
}

.infoContainer {
  flex: 1;
  display: flex;
  gap: 1rem;
  flex-direction: column;
  padding: 2rem 0 0 0;
  width: 0;
  overflow: hidden;
  /* max-width: 40rem; */
}

.infoContainer > div {
    max-width: 40rem; 
}

.infoContainer h1 {
  font-size: 2rem;
  margin-bottom: 2rem;
}

.containers {
  margin-top: 1rem;
  padding: 0 1rem 1rem 0;
}

.containers > li:nth-child(1) > p {
  opacity: 0.6;
}

.orderContainer {
  padding: 0 0 0.1rem;
}

.orderContainer > span {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  background-color: var(--sandWhite);
}

.deliveryWrapper {
  padding: 1rem;
  border: 1px solid var(--borderSandWhite);
  border-radius: 0.2rem;
  display: flex;
  flex-direction: column;
  gap: 0.7rem;
}

.deliveryWrapper > h6 {
  font-weight: 500;
}

.deliveryWrapper > div:nth-child(2) {
  display: flex;
  flex-direction: column;
  gap: 0.2rem;
}

.deliveryWrapper > p {
  text-align: left;
}

.delivery {
  display: flex;
  gap: 0.5rem;
}

.allergensContainer {
  background-color: var(--sandWhite);
  width: 100%;
}

.allergensContainer > ol {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(5rem, 1fr));
  gap: 0.5rem;
}

.allergensContainer > ol > li {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 0.5rem;
  border: 1px solid var(--brown);
  padding: 1rem;
  border-radius: 0.2rem;
}

.allergensContainer > ol > li > img {
  width: 2rem;
  display: block;
  object-fit: cover;
}

.containerStorage {
  background-color: var(--sandWhite);
  width: 100%;
}

.boxComposition {
  background-color: var(--sandWhite);
  width: 100%;
}

.swiperBoxComposition {
  width: 100%;
  height: 21rem;
}

.slideBoxComposition {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 1rem;
  border: 1px solid var(--brown);
  padding: 1rem;
  border-radius: 0.2rem;
  text-align: center;
  position: relative;
  overflow: hidden;
  height: 19rem;
  cursor: grab;
  width: 170px !important;
}

.slideBoxComposition > img {
  max-width: 7rem;
  object-fit: cover;
  display: block;
}

.slideBoxComposition h3 {
  font-size: 0.8rem;
}

.slideBoxComposition > div:nth-child(2) > p {
  font-size: 0.8rem;
}

.showDetailsButtonContainer {
  margin-top: auto;
  display: flex;
  gap: 0.3rem;
  flex-direction: column;
}

.showDetailsButtonContainer > span {
  background-color: var(--brown);
  color: var(--sandWhite);
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  width: fit-content;
  margin-inline: auto;
  cursor: pointer;
  border: 1px solid transparent;
}

.showDetailsButtonContainer > p {
  font-size: 0.8rem;
  font-weight: 500;
}

.showDetailsButtonContainer > span:hover {
  color: var(--brown);
  background-color: var(--sandWhite);
  border: 1px solid var(--brown);
}

.showMoreContainer {
  position: absolute;
  background-color: var(--lightSecondary);
  width: 100%;
  height: 100%;
  bottom: 0;
  left: 0;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.showMoreContainer > div:first-child {
  display: flex;
  flex-direction: column;
  gap: 0.3rem;
}

.showMoreContainer > div:first-child > p,
.showMoreContainer > div:last-child > p:first-child {
  font-size: 0.8rem;
  font-weight: 500;
}

.showMoreContainerList {
  display: flex;
  justify-content: center;
  gap: 0.2rem;
  word-break: break-all;
}

.hideDetailsButton {
  background-color: var(--brown);
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid transparent;
  width: fit-content;
  margin-inline: auto;
  border-radius: 50%;
  cursor: pointer;
  color: var(--sandWhite);
}

.hideDetailsButton > svg {
  transform: rotate(180deg);
}

.hideDetailsButton:hover {
  border: 1px solid var(--brown);
  background-color: var(--sandWhite);
  color: var(--brown);
}

.quantityContainer {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  gap: 1rem;
  border: 1px solid var(--borderSandWhite);
  padding: 1rem;
}

.priceAndQuantity {
  display: flex;
  gap: 1rem;
}

.priceWrapper {
  border: 1px solid var(--borderSandWhite);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 1rem;
  border-radius: 0.2rem;
}

.quantityWrapper {
  border: 1px solid var(--borderSandWhite);
  display: flex;
  border-radius: 0.2rem;
  height: 100%;
}

.quantityWrapper > div {
  padding: 0 1rem;
  flex: 1 1 0px;
  height: 3rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.quantityWrapper > div:first-child {
  cursor: pointer;
}

.quantityWrapper > div:nth-child(2) {
  width: 40px;
}

.quantityWrapper > div:last-child {
  border-right: unset;
  cursor: pointer;
}

.quantityContainer > button {
  width: 100%;
}


@media (max-width: 1360px) {
  .imgContainer {
    flex-direction: column-reverse;
    padding: 1rem 0 0 1rem;
  }

  .albumContainer > ol {
    flex-direction: row;
  }

  .albumContainer > ol > li > img {
    max-width: 84px;
  }

  .container {
    gap: 2rem;
  }
}

@media (max-width: 1230px) {
  .imgWrapper {
    width: 50vw;
    height: unset;
  }
}

@media (max-width: 1215px) {
  .slider {
    padding: 0 1rem 0 0;
  }
}

@media (max-width: 985px) {
  .infoContainer {
    padding: 4rem 0 0 0;
    padding: 1rem;
    width: 100%;
  }

  .imgWrapper {
    width: unset;
  }

  .infoContainer > div {
    max-width: unset;
  }

  .infoContainer h1 {
    text-align: center;
  }

  .container {
    flex-direction: column;
    gap: 1rem;
  }

  .albumContainer > ol {
    flex-direction: column;
  }

  .containers {
    padding: 0 0 1rem 0;
  }

  .imgContainer {
    flex-direction: row;
    padding: 1rem;
    width: 100%;
    justify-content: center;
  }

  .productDescription {
    width: 100%;
    display: flex;
    justify-content: center;
  }

  .productDescription > p {
    text-align: center;
  }

  .orderContainer > span > p {
    text-align: center;
  }
}

@media (max-width: 640px) {
  .infoContainer h1 {
    padding: 0 1rem;
  }

  .container {
    margin-top: 0;
  }

  .orderContainer > span {
    gap: 1.7rem;
  }

  .quantityContainer {
    padding: 0.5rem;
  }

  .priceAndQuantity {
    gap: 0.5rem;
    width: 100%;
  }

  .priceWrapper {
    flex: 1;
  }

  .quantityWrapper {
    flex: 1;
  }
}

@media (max-width: 550px) {
  .infoContainer {
    padding: 0;
  }

  .slider,
  .allergensContainer,
  .swiperBoxComposition {
    padding: 0 1rem;
  }

  .infoContainer h1 {
    font-size: 1.5rem;
  }

  .containers {
    padding: 0;
  }

  .orderContainer {
    padding: 0 1rem 0.1rem;
  }
  .containerStorage {
    padding: 0 1rem;
  }

  .quantityContainer {
    justify-content: center;
  }

  .container {
    gap: 2rem;
    text-align: center;
  }

  .infoContainer h1 {
    text-align: center;
  }

  .imgContainer {
    flex-direction: column-reverse;
  }

  .albumContainer > ol {
    flex-direction: row;
  }

  .quantityContainer > button {
    width: 100%;
  }

  .quantityWrapper > div {
    padding: 0.7rem;
  }
}

@media (max-width: 440px) {
  .quantityContainer {
    justify-content: flex-start;
    align-items: flex-start;
    gap: 0.5rem;
    height: unset;
  }

  .quantityContainer > button > span {
    padding: 0 1rem;
  }
}

@media (max-width: 415px) {
  .albumContainer > ol > li > img {
    width: 100%;
  }
}

@media (max-width: 357px) {
  .quantityContainer > button > span {
    padding: 0 0.5rem;
  }

  .quantityWrapper > div {
    padding: 0.4rem;
  }
}
