.container {
  position: relative;
  width: 100vw;
  max-height: 100%;
  height: 100%;
  background-color: var(--sandWhite);
}

@media (max-width: 640px) {
  .container {
    max-height: calc(100dvh - 9rem);
    overflow: hidden;
    /* height: 100vh; */
  }
}
