.menu {
  width: 100%;
}

.menuSlides {
  cursor: pointer;
  padding: 0.3rem 0.6rem;
  border-radius: 0.2rem;
  border: 1px solid var(--borderSandWhite) !important;
  width: fit-content !important;
  transition: all 0.8s;
  display: flex;
  justify-content: center;
  align-items: center;
}

.menuSlides:hover {
  border: 1px solid var(--brown) !important
}

.menuSlides:not(:first-child) {
 margin-left: 1.2rem;
}

.menuSlides > h6 {
  font-weight: 400;
  font-size: 1.1rem;
  white-space: nowrap;
}

@media (max-width: 550px) {
 .menu {
  padding: 0 1rem;
 }

 .menuSlides:not(:first-child) {
  margin-left: 0.5rem;
 }
}
