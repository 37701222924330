.menu {
  width: 100%;
  margin-top: 0.5rem;
}

.menuSlides {
  cursor: pointer;
  text-align: center;
  padding: 0.2rem 0.6rem;
  border-radius: 0.2rem;
  width: fit-content !important;
  border: 1px solid var(--borderSandWhite);
  height: 1.8rem;
  transition: background-color 0.3s, color 0.3s;
}

.menuSlides:hover {
  background-color: var(--brown);
  color: var(--sandWhite);
}

.menuSlides:not(:first-child) {
  margin-left: 0.7rem;
}

.menuSlides > p {
  font-weight: 400;
  font-size: 0.9rem;
  white-space: nowrap;
  line-height: 1.1rem;
}
