.splitterContainer {
  width: "100%";
  min-height: 10rem;
  display: flex;
  justify-content: center;
  text-align: center;
  flex-direction: column;
  gap: 0.4rem;
  color: var(--brown);
  padding: 7rem 1rem;
}

.splitterContainer > h5 {
  opacity: 0.9;
}


@media (max-width: 640px) {
  .splitterContainer {
    padding: 4rem 1rem;
    min-height: unset;
  }
}

@media (max-width: 380px) {
  .splitterContainer {
    padding: 2rem 1rem;
  }
}





