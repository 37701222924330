.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 2rem;
  min-height: calc(100vh - 21.375rem);
}

.container > h1 {
  text-transform: none !important;
  max-width: 30ch;
  text-align: center;
}


@media (max-width: 1100px) {
  .container {
    min-height: calc(100vh - 18.75rem);
  }
}

@media (max-width: 640px) {
  .container {
    min-height: calc(100vh - 12.45rem);
  }
}

@media (max-width: 400px) {
  .container {
    gap: 0.5rem;
  }
}
