.emptyContainer {
  display: flex;
  height: 100%;
  width: 100%;
}

.emptyCartAnimationContainer > h1 {
  text-align: center;
  text-transform: none !important;
  max-width: 30ch;
}

.emptyCartAnimationContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  height: 100%;
}

.emptyCartAnimationContainer button {
  min-width: 15rem;
}

.animation {
  width: 500px !important;
}

.animation > div:nth-child(2) {
  top: 50%;
  left: 53%;
  transform: translate(-53%, -50%);
}

.animation > div:nth-child(2) > img {
  scale: 0.3
}

.animationContainer {
  min-height: unset;
}

@media (max-width: 640px) {
  .emptyContainer {
    padding: 0 1rem 3rem;
  }

  .animation {
    width: 90% !important;
  }

  .emptyCartAnimationContainer button {
    width: 100%;
  }
}