.header_photo_container {
  flex: 1 1 0px;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  padding: 3rem 4rem 3rem 3rem;
}

.header_photo_container div:nth-child(1) {
  aspect-ratio: 3/3;
  max-height: 23rem;
  max-width: 23rem;
  overflow: hidden;
  position: relative;
}

.header_photo_container img {
  width: 100%;
  min-height: 100%;
  /* aspect-ratio: 3/3; */
  object-fit: cover;
  filter: brightness(87%);
}

.photo_text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  flex-direction: column;
  text-align: center;
  width: 100%;
  color: var(--sandWhite)
}

.photo_text p {
  letter-spacing: 0.1rem;
}

.photo_text h3 {
  letter-spacing: 0.3rem;
}


@media (max-width: 1100px) {
  .header_photo_container {
    display: none;
  }
}