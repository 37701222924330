.orderContainer {
  height: 100%;
  display: flex;
  flex-direction: row;
}

.userInformation {
  flex: 5;
  padding: 4rem 2rem 2rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  max-height: 100%;
  overflow: auto;
}

.form {
  flex: 2 1 0px;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  text-align: left;
  border-top: 1.5px solid var(--transparentDarkBlue);
}

.duo {
  display: flex;
  gap: 1rem;
}

.duo > span {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 0.5rem;
  flex: 1 1 0px;
}

.form > span:not(:nth-child(2)) {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  align-items: flex-start;
}

.duo > span > input {
  width: 100%;
  height: 3rem;
}

.form input {
  border: 1px solid var(--borderSandWhite);
  border-radius: 0.2rem;
  padding: 1rem;
  background-color: var(--sandWhite);
  color: rgb(177, 177, 177);
}

.form label {
  font-weight: 500;
  position: relative;
}

.form label:after {
  content: "*";
  position: absolute;
  right: -0.7rem;
  top: 0;
  color: red;
}

.calendarContainer {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.calendarContainer > p {
  position: relative;
  width: fit-content;
  font-weight: 500;
  font-size: 14.4px;
}

.calendarContainer > p:after {
  content: "*";
  position: absolute;
  right: -0.7rem;
  top: 0;
  color: red;
}

.name {
  margin-top: 2rem;
}

.name input {
  width: 100%;
  height: 3rem;
}

.form .message label:after {
  content: unset;
}

.calendarWrapper {
  display: flex;
  flex-direction: row;
  gap: 1rem;
  border: 1px solid var(--borderSandWhite);
  border-radius: 0.2rem;
  position: relative;
}

.orderingDateInfo {
  flex: 1;
  padding: 4rem 1rem 0;
}

.calendar {
  flex: 1;
  margin: unset !important;
  margin-right: auto;
}

.message textarea {
  min-height: 10rem;
  max-width: 100%;
  width: 100%;
  border: 1px solid var(--borderSandWhite);
  border-radius: 0.2rem;
  padding: 1rem;
  background-color: var(--sandWhite);
  color: rgb(177, 177, 177);
}

@media (max-width: 870px) {
  .orderContainer {
    flex-direction: column;
  }

  .duo {
    display: flex;
    gap: 1rem;
    flex-direction: column;
  }
}

@media (max-width: 640px) {
  .userInformation {
    padding: 0 1rem 1rem;
  }

  .calendar {
    width: 100% !important;
    margin: auto !important;
    margin-right: unset;
  }

  .calendarWrapper {
    flex-direction: column;
  }

  .orderingDateInfo {
    flex: 0;
    padding: 0 1rem 1rem;
  }
}
