.languageMenuContainer {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  font-size: 0.9rem;
  font-weight: 400;
  cursor: pointer;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
}

.languageMenuContainer > span {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.languageIconAndNameContainer {
  margin-right: auto;
  width: 100%;
  position: relative;
}

.languagesMenu {
  width: 100%;
}

.languagesMenu > div {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  padding: 1.6rem 0 0 0;
}

.languageIconAndNameContainerAfter::after {
  content: "";
  position: absolute;
  width: calc(100% + 0.6rem);
  bottom: -0.8rem;
  left: -0.3rem;
  border-bottom: 1px solid var(--borderSandWhite);
}

.languagesMenu > div > li {
  display: flex;
  justify-content: flex-start;
  gap: 0.7rem;
  font-style: italic;
  opacity: 0.9;
  font-size: 0.9rem;
  font-weight: 300;
}

.languagesMenu > div > li > img {
  object-fit: cover;
}

.languagesMenu > div > li > span:nth-child(1) {
  width: 1.7rem;
  display: flex;
}

.languagesMenu > div > li > span:nth-child(1) img {
  width: 100%;
  object-fit: cover;
}

.languagesMenu > li > span:nth-child(2) {
  opacity: 0.7;
  font-weight: 400;
  font-size: 0.7rem;
  font-style: italic;
}

.radial {
  margin-left: auto;
}
