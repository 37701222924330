/* FONTS */

@import url("https://fonts.googleapis.com/css2?family=Cormorant+Garamond:ital,wght@1,500&display=swap");

@font-face {
  font-family: "Recoleta 100";
  src: url("./fonts/RecoletaAlt-Thin.eot");
  src: local("./fonts/Recoleta Alt Thin"), local("RecoletaAlt-Thin"),
    url("./fonts/RecoletaAlt-Thin.eot?#iefix") format("embedded-opentype"),
    url("./fonts/RecoletaAlt-Thin.woff2") format("woff2"),
    url("./fonts/RecoletaAlt-Thin.woff") format("woff"),
    url("./fonts/RecoletaAlt-Thin.ttf") format("truetype");
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: "Recoleta 200";
  src: url("./fonts/Recoleta-Medium.eot");
  src: local("Recoleta Medium"), local("Recoleta-Medium"),
    url("./fonts/Recoleta-Medium.eot?#iefix") format("embedded-opentype"),
    url("./fonts/Recoleta-Medium.woff2") format("woff2"),
    url("./fonts/Recoleta-Medium.woff") format("woff"),
    url("./fonts/Recoleta-Medium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "Recoleta 300";
  src: url("./fonts/RecoletaAlt-SemiBold.eot");
  src: local("Recoleta Alt SemiBold"), local("RecoletaAlt-SemiBold"),
    url("./fonts/RecoletaAlt-SemiBold.eot?#iefix") format("embedded-opentype"),
    url("./fonts/RecoletaAlt-SemiBold.woff2") format("woff2"),
    url("./fonts/RecoletaAlt-SemiBold.woff") format("woff"),
    url("./fonts/RecoletaAlt-SemiBold.ttf") format("truetype");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: "Recoleta 400";
  src: url("./fonts/RecoletaAlt-Medium.eot");
  src: local("Recoleta Alt Medium"), local("RecoletaAlt-Medium"),
    url("./fonts/RecoletaAlt-Medium.eot?#iefix") format("embedded-opentype"),
    url("./fonts/RecoletaAlt-Medium.woff2") format("woff2"),
    url("./fonts/RecoletaAlt-Medium.woff") format("woff"),
    url("./fonts/RecoletaAlt-Medium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "Recoleta regular";
  src: url("./fonts/RecoletaAlt-Regular.eot");
  src: local("Recoleta Alt Regular"), local("RecoletaAlt-Regular"),
    url("./fonts/RecoletaAlt-Regular.eot?#iefix") format("embedded-opentype"),
    url("./fonts/RecoletaAlt-Regular.woff2") format("woff2"),
    url("./fonts/RecoletaAlt-Regular.woff") format("woff"),
    url("./fonts/RecoletaAlt-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Minerva Modern Black";
  font-style: normal;
  font-weight: normal;
  src: url("./fonts/MinervaModern Black.woff") format("woff");
}

@font-face {
  font-family: "Minerva Modern Black Italic";
  font-style: italic;
  font-weight: normal;
  src: url("./fonts/MinervaModern BlackItalic.woff") format("woff");
}

@font-face {
  font-family: "Minerva Modern Bold";
  font-style: normal;
  font-weight: bold;
  src: url("./fonts/MinervaModern Bold.woff") format("woff");
}

@font-face {
  font-family: "Minerva Modern Bold Italic";
  font-style: italic;
  font-weight: bold;
  src: url("./fonts/MinervaModern BoldItalic.woff") format("woff");
}

@font-face {
  font-family: "Minerva Modern Italic";
  font-style: italic;
  font-weight: normal;
  src: url("./fonts/MinervaModern Italic.woff") format("woff");
}

@font-face {
  font-family: "remachine_script";
  src: url("./fonts/remachinescript_personal_use-webfont.woff2") format("woff2");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "remachine_script2";
  src: url("./fonts/remachinescript_personal_use-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

:root {
  /* color */
  --lightGreen: rgb(135, 189, 135);
  --red: rgb(255, 0, 0);
  --black: rgb(29, 29, 29);
  --darkBlue: rgb(16, 27, 44);
  --lightBlue: rgb(29, 50, 82);
  --brownGrey: #544841;
  --brown: rgb(16, 15, 29);
  --beige: rgb(147, 97, 68);
  --lightBeige: rgb(215, 189, 184);
  --darkerSandWhite: rgb(239, 238, 232);
  --sandWhite: rgb(251, 251, 251);
  --light: rgb(249, 248, 246);
  --transparentBlack: rgba(39, 39, 39, 0.41);
  --transparentLightBeige: rgba(233, 227, 216, 0.754);
  --secondary: rgb(239, 232, 231);
  --lightSecondary: rgb(245, 240, 239);
  --transparent: rgba(255, 255, 255, 0);
  --darkBlue: rgb(33, 44, 56);
  --transparentDarkBlue: rgba(33, 44, 56, 0.25);

  /*border colors*/
  --borderSandWhite: rgba(223, 223, 223, 0.896);

  /*mobile menus border radius */
  --borderMenuRadius: 0.2rem;

  /*border shadows*/
  --boxShadowGrey: -1px 18px 26px -8px rgba(208, 208, 208, 0.245);

  /*placeholder colors*/
  --placeholderTransparentBrown: rgba(109, 72, 51, 0.523);

  /* typography */
  --fs-xxl: clamp(3rem, 10vw + 1rem, 12rem);
  --fs-xl: clamp(3rem, 8vw + 3rem, 6.5rem);
  --fs-l: clamp(2rem, 5vw + 1rem, 2.5rem);
  --fs-600: clamp(1rem, 3vw + 1rem, 2rem);
  --fs-500: clamp(0.7rem, 3vw + 1rem, 1.5rem);
  --fs-400: clamp(0.3rem, 6vw + 1rem, 1.2rem);
  --fs-350: clamp(0.3rem, 6vw + 1rem, 1rem);
  --fs-300: clamp(0.3rem, 6vw + 1rem, 0.8rem);
  --fs-200: clamp(0.3rem, 6vw + 1rem, 0.7rem);

  /* swiper */
  --swiper-pagination-color: var(--brown);
  --swiper-pagination-bullet-size: 8px;
  --swiper-pagination-bullet-width: 0.5rem;
  --swiper-pagination-bullet-height: 0.5rem;
  --swiper-pagination-bullet-inactive-color: rgba(33, 44, 56, 0.327);
  --swiper-pagination-bullet-inactive-opacity: 1;
  --swiper-pagination-bullet-opacity: 1;
  --swiper-pagination-bullet-horizontal-gap: 4px;
  --swiper-pagination-bullet-vertical-gap: 6px;
}

* {
  -webkit-font-smoothing: antialiased;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  padding: 0;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* user-select: none; */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@media (prefers-reduced-motion: no-preference) {
  html {
    scroll-behavior: smooth;
    /* scroll-padding-top: 1rem; */
  }

  :has(:target) {
    scroll-behavior: smooth;
    /* scroll-padding-top: 1rem; */
  }
}

.scroll-to-top {
  transition: scroll-behavior 0.5s;
}

/* Scroll-bar */

/* width */
::-webkit-scrollbar {
  width: 4px;
}

/* Track */
::-webkit-scrollbar-track {
  background-color: transparent;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: var(--brown);
}

html,
body {
  box-sizing: border-box;
  background-color: var(--sandWhite);
  overflow-x: hidden;
  overscroll-behavior: none;
}

/* Typography */

p {
  max-width: 70ch;
  text-wrap: pretty;
  /* -ms-user-select: none;
  user-select: none; */
}

/* SUBTITLE */

h1,
h2,
h3,
h4,
h5,
h6 {
  text-wrap: balance;
}

h1 {
  font-family: "Recoleta regular";
  font-weight: normal;
  font-size: var(--fs-l);
}

/* SMALLER SUBTITLE */

h3 {
  font-family: Minerva Modern;
  font-weight: normal;
  text-transform: uppercase;
  font-size: var(--fs-500);
}

/* TITLE */

h4 {
  text-transform: uppercase;
  font-weight: 400;
}

h5 {
  text-transform: uppercase;
  font-weight: 400;
  letter-spacing: 0.05rem;
  font-style: italic;
}

h6 {
  font-weight: 400;
  font-size: 1rem;
}

input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration {
  display: none;
}

input:focus,
textarea:focus {
  outline: none;
}

input[type="text"],
input[type="email"],
textarea {
  font-size: var(--fs-400);
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px var(--sandWhite) inset !important;
}

::placeholder,
::-webkit-input-placeholder {
  color: var(--brown);
  opacity: 0.5; /* Firefox */
  font-size: 1rem;
}

.controlError {
  color: rgb(225, 129, 116);
  position: absolute;
  bottom: -1.4rem;
  font-size: 0.9rem;
}

.bar {
  background-color: rgb(229, 228, 218);
  width: 2.3rem;
  height: 0.3rem;
  position: absolute;
  top: 0.7rem;
  border-radius: 1rem;
  left: 50%;
  transform: translateX(-50%);
  z-index: 8;
}

/* Hover lists */

.list {
  background: linear-gradient(currentColor 0 0) bottom / var(--d, 0) 1px
    no-repeat;
  transition: 0.35s;
}

.list:hover {
  --d: 100%;
}

ul,
ol {
  list-style: none;
}

a {
  color: inherit;
  text-decoration: none;
  -webkit-tap-highlight-color: transparent;
}

span,
div,
input,
textarea,
button,
select,
a {
  -webkit-tap-highlight-color: transparent;
}

.pageHightAjs {
  height: 6rem;
  background-color: var(--sandWhite);
}

.popup {
  position: absolute;
  right: -10.5rem;
  bottom: 70%;
  width: 10rem !important;
  background: var(--secondary);
  color: var(--brown);
  border: 1px solid var(--brown);
  border-radius: 0.2rem;
  text-align: left;
  overflow: hidden;
  z-index: 1;
}

.popup > div {
  padding: 0.5rem;
  text-wrap: pretty;
}

/* SWIPERS */

.mobileDiscoverButton {
  position: absolute;
  z-index: 1;
  bottom: 1rem;
  right: 1rem;
  width: 4rem;
  height: 4rem;
  border-radius: 50%;
  background-color: var(--sandWhite);
  display: flex;
  justify-content: center;
  align-items: center;
}

.mobileDiscoverButton a {
  border: 1px solid var(--brown);
  width: 95%;
  height: 95%;
  border-radius: inherit;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: var(--fs-200);
}

.containerStyle {
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 4rem;
  padding: 4rem 0 7rem;
  color: var(--brown);
  width: 100%;
}

/* flavour of the month */

.flavourOfTheMonth {
  padding: 7rem 1rem 4rem !important;
}

.pageRoutesWrapper {
  padding: 0rem 0 7rem;
}

.aboutUs,
.contactUs,
.faq,
.tac,
.originsOfOurIng,
.legalNotice {
  padding: 0 !important;
}

html:has(.requires-no-scroll-cart),
body:has(.requires-no-scroll-cart) {
  overflow: hidden;
  touch-action: none;
  -webkit-overflow-scrolling: touch !important;
  position: relative;
  margin: 0;
  padding: 0;
}

.Mui-selected {
  background-color: var(--brown) !important;
}

.MuiPickersDay-today {
  border: none !important;
}

@media (max-width: 1100px) {
  html:has(.requires-no-scroll),
  body:has(.requires-no-scroll) {
    overflow: hidden;
    touch-action: none;
    -webkit-overflow-scrolling: touch !important;
  }

  body:has(.requires-no-scroll) {
    position: relative;
    margin: 0;
    padding: 0;
  }

  h1 {
    font-size: var(--fs-600);
  }

  .pageHightAjs {
    height: 4rem;
  }
}

@media (max-width: 640px) {
  .flavourOfTheMonth {
    padding: 2rem 1rem 3rem !important;
  }

  .pageRoutesWrapper {
    padding: 0 0 4rem;
  }

  .aboutUs,
  .contactUs,
  .faq,
  .tac,
  .originsOfOurIng,
  .legalNotice {
    padding: 0 0 4rem;
  }

  .swiper .swiper-wrapper .swiper-slide .mobileDiscoverButton,
  .swiper .swiper-wrapper .swiper-slide .mobileDiscoverButton a {
    scale: 0;
  }

  .swiper .swiper-wrapper .swiper-slide-active .mobileDiscoverButton,
  .swiper .swiper-wrapper .swiper-slide-active .mobileDiscoverButton a {
    scale: 1;
  }

  /* .swiper-pagination {
    transform: translateY(0.1rem) !important;
  } */

  h1 {
    font-size: var(--fs-500);
  }

  h5 {
    text-transform: capitalize;
  }

  .containerStyle {
    width: 100%;
    position: relative;
    margin-inline: auto;
    padding: 2rem 1rem 7rem;
  }

  .pageHightAjs {
    display: none;
  }
}

@media (max-width: 425px) {
  .popup {
    position: absolute;
    right: unset;
    left: 0;
    bottom: 100%;
  }
}

@media (max-width: 380px) {
  .swiper
    .swiper-wrapper
    .swiper-slide:nth-last-child(1)
    .mobileDiscoverButton {
    right: 1.5rem;
  }

  .mobileDiscoverButton a {
    font-size: 0.5rem;
  }

  .pageRoutesWrapper {
    padding: 0 0 2rem;
  }
}
