.header_dropdown {
  display: flex;
  background-color: var(--sandWhite);
  height: fit-content;
  width: 100%;
  z-index: 6;
  color: var(--brown);
  justify-content: space-between;
}

.header_list {
  flex: 1.8 1 0px;
  padding: 3rem 2rem 3rem 4rem;
}

@media (max-width: 1100px) {
  .header_dropdown {
    z-index: 6;
    flex-direction: column;
    max-height: calc(100dvh - 83px + 1rem);
    overflow: hidden;
    touch-action: none;
    text-transform: capitalize;
    position: relative;
    background-color: var(--sandWhite);
  }

  .header_list {
    padding: 0 1rem 1rem;
  }
}

@media (max-width: 640px) {
  .header_dropdown {
    max-height: calc(100dvh - 73px + 1rem);
  }

  .header_list {
    padding: 0 0 1rem;
  }
}
