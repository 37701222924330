.dropdown {
  background-color: var(--sandWhite);
  color: var(--brown);
  touch-action: none;
  overflow: auto;
}

.dropdownOptions > li {
  text-transform: none;
  font-size: 0.9rem;
  font-weight: normal;
  border-radius: 0.2rem;
  border: 1px solid var(--borderSandWhite);
  background-color: var(--sandWhite);
  padding: 0.8rem 0.6rem;
  width: 100%;
  position: relative;
}

.dropdownOptions > li > a {
  width: 100%;
}

.dropdownOptions {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0.5rem 1rem 1rem;
}
