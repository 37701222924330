.container {
  background-color: var(--sandWhite);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 2rem 3rem;
}

.container > div {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.dropdownContainer {
  position: relative;
  width: 15rem;
}

.dropdownContainer > div:nth-child(1) {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 0.6rem;
  cursor: pointer;
  padding: 0.4rem 0.3rem;
}

.downArrow {
  transform: rotate(0deg);
}

.upArrow {
  transform: rotate(180deg);
}

.radial > svg {
  margin-bottom: 0.3rem;
}

.dropdownContainer > div:nth-child(2) {
  border: 1px solid var(--borderSandWhite);
  position: absolute;
  width: 100%;
  overflow: hidden;
  z-index: 2;
  background-color: var(--sandWhite);
  /* top: calc(100% + 0.2rem); */
  border-radius: 0.2rem;
}

.dropdownContainer > div:nth-child(2) > ul {
  display: flex;
  flex-direction: column;
}

.dropdownContainer > div:nth-child(2) > ul > li {
  padding: 0.5rem;
  cursor: pointer;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 0.5rem;
}

.dropdownContainer > div:nth-child(2) > ul > li > span:last-child {
  margin-left: auto;
}

.dropdownContainer > div:nth-child(2) > ul > li:hover {
  background-color: var(--secondary);
}

.categoryContainer {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 0.5rem;
  position: relative;
}

.categoryContainer > span {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--brown);
  border-radius: 0.2rem;
  color: var(--sandWhite);
  padding: 0.2rem;
  min-width: 1.6rem;
}

@media (max-width: 640px) {
  .container {
    padding: 0.5rem 1rem 1rem;
    background-color: var(--lightSecondary);
  }

  .radial > svg {
    margin-bottom: 0;
  }
}
