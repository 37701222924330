.wrapperMenu {
  width: 1.3rem;
  height: 1.3rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  cursor: pointer;
  transition: transform 330ms ease-out;
}

.wrapperMenu.open {
  transform: rotate(-45deg);
}

.lineMenu {
  background-color: var(--sandWhite);
  border-radius: 5px;
  width: 100%;
  height: 2px;
  background-color: var(--brown);
}

.lineMenu.half {
  width: 50%;
}

.lineMenu.start {
  transition: transform 330ms cubic-bezier(0.54, -0.81, 0.57, 0.57);
  transform-origin: right;
}

.open .lineMenu.start {
  transform: rotate(-90deg) translateX(1.5px);
}

.lineMenu.end {
  align-self: flex-end;
  transition: transform 330ms cubic-bezier(0.54, -0.81, 0.57, 0.57);
  transform-origin: left;
}

.open .lineMenu.end {
  transform: rotate(-90deg) translateX(-2.5px);
}
