.container {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.container > h6 {
  font-weight: 500;
  text-align: left;
}

.wrapper {
  display: flex;
  gap: 0.5rem;
}

.wrapper > li {
  padding: 0.5rem 0.7rem;
  border-radius: 0.2rem;
  display: flex;
  gap: 0.7rem;
  cursor: pointer;
  justify-content: center;
  width: fit-content;
  border: 1px solid var(--borderSandWhite);
  color: var(--brown)
}

.wrapper > li > span {
  flex: 1;
  white-space: nowrap;
  display: flex;
  justify-content: center;
}
