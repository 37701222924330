.container {
  display: flex;
  flex-direction: row;
  width: 60rem;
  justify-content: space-between;
}

.form {
  flex: 2 1 0px;
  padding: 1rem 1rem;
  display: flex;
  flex-direction: column;
  gap: 1.3rem;
  text-align: left;
}

.info {
  flex: 1 1 0px;
  padding: 1rem 1rem;
  border-right: 1px solid var(--borderSandWhite);
  text-align: left;
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.info > p > a {
  font-weight: 600;
}

.info > p {
  border-left: 0.2rem solid var(--borderSandWhite);
  padding: 0 0 0 0.5rem;
}

.info > a {
  border-left: 0.2rem solid var(--borderSandWhite);
  padding: 0 0 0 0.5rem;
}

.requiredField {
  margin-left: 1rem;
}

.requiredField > span:before {
  content: "* ";
  color: red;
  font-size: 1.1rem;
}

@media (max-width: 1100px) {
  .container {
    flex-direction: column;
    width: 90%;
    gap: 2rem;
  }

  .info {
    border-right: unset;
  }

  .form,
  .info {
    padding: unset;
  }

  .requiredField {
    margin-left: unset;
  }
}

@media (max-width: 640px) {
  .container {
    width: 100%;
  }

}
