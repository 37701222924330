.PaginationButtonsContainer {
  display: flex;
  width: fit-content;
  align-items: center;
  justify-content: center;
  /* gap: 1rem; */
  border: 1px solid var(--borderSandWhite);
  align-self: center;
  border-radius: 0.2rem;
}

.PageNumbersContainer > button:first-child svg {
  transform: rotate(-90deg);
}

.PageNumbersContainer > button:last-child svg {
  transform: rotate(90deg);
}

.PageNumbersContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  /* max-width: calc(100% - 2rem); */
  overflow: hidden;
}

.PageNumbersContainer > button {
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  background-color: transparent;
  padding: 0.5rem;
  min-width: 2.5rem !important;
  max-width: 2.5rem !important;
  height: 2.5rem;
  cursor: pointer;
  font-weight: 600;
  font-size: 1rem;
}

.PageNumbersContainer > button:hover {
  background-color: var(--lightSecondary);
}

.PageNumbersContainer > button:not(:last-child) {
  border-right: 1px solid var(--borderSandWhite);
}
