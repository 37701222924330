.options_dropdown {
  display: flex;
  background-color: var(--sandWhite);
  height: fit-content;
  width: fit-content;
  z-index: 6;
  color: var(--brown);
  border-bottom-left-radius: 0.2rem;
  margin-left: auto;
  gap: 0;
  flex-direction: column;
  overflow: hidden;
  /* touch-action: none; */
  margin-right: 0.1rem;
}

.name {
  font-size: 1rem;
}

@media (max-width: 1100px) {
  .options_dropdown {
    max-height: calc(100dvh - 83px + 1rem);
  }
}

@media (max-width: 640px) {
  .barContainer {
    position: relative;
    min-height: 2rem;
    background-color: var(--sandWhite);
  }

  .options_dropdown {
    max-height: calc(100dvh - 10rem);
    width: 100%;
    z-index: -2;
    border-top-left-radius: 0.2rem;
    border-top-right-radius: 0.2rem;
    border-bottom-left-radius: 0;
    background-color: var(--light);
    border-top: 1px solid var(--borderSandWhite);
    margin-right: unset;
  }
}
