.logoAndPathContainer {
  width: 10rem;
  min-height: 30px;
}

.logoAndPathContainer > div {
  position: absolute;
}

.logoAndPathContainer > div:nth-child(1) {
  position: absolute;
  z-index: 10;
  left: 50%;
  transform: translate(-50%, -50%);
}

.logoAndPathContainer > div:nth-child(1) > img {
  max-width: 100%;
  display: block;
  scale: 0.4;
}

.logoAndPathContainer div:nth-child(1) span {
  font-size: var(--fs-l);
}

.logoAndPathContainer div:nth-child(2) span {
  font-weight: 600;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 80vw;
}

@media (max-width: 380px) {
  .logoAndPathContainer div:nth-child(1) span {
    font-size: var(--fs-600);
  }
}
