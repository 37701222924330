.list {
  background: linear-gradient(currentColor 0 0) bottom / var(--d, 0) 1px
    no-repeat;
  transition: 0.35s;
  margin-top: 0.1rem;
}

.list:hover {
  --d: 100%;
}

.iconWrapper {
  position: relative;
}

.offersCountMobile {
  border-radius: 50%;
  background-color: var(--red);
  width: 1.1rem;
  height: 1.1rem;
  text-align: center;
  position: absolute;
  right: -0.2rem;
  top: 0.2rem;
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.offersCountMobile > div > p {
  color: var(--sandWhite);
  font-size: 0.7rem;
  font-weight: 700;
  line-height: 0.5rem !important;
}

@media (max-width: 640px) {
  .list:hover {
    --d: 0%;
  }

  .offersCountMobile {
    width: 1rem;
    height: 1rem;
    right: -0.6rem;
    top: -0.1rem;
  }

  .offersCountMobile > div > p {
    line-height: 0.7rem !important;
  }
}
