.subMenu {
  padding: 0;
  display: flex;
  flex-direction: column;
  gap: 0.3rem;
  margin-top: 0.6rem;
  font-style: italic;
  text-transform: capitalize;
  position: absolute;
}

.subMenu_items {
  opacity: 0.7;
  margin-left: 0.6rem;
  font-size: var(--fs-350);
  width: fit-content;
}

@media (max-width: 1100px) {
  .subMenu {
    padding: 0;
    margin-top: unset; 
    position: static;
    width: fit-content;
  }
}

@media (max-width: 640px) {
  .subMenu_items {
    font-size: var(--fs-300);
  }
}
