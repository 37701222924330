.ul {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-auto-rows: 200px;
}

.ul > li {
  cursor: pointer;
}

.ul > li ol > li {
  cursor: pointer;
  background: linear-gradient(currentColor 0 0) bottom / var(--d, 0) 1px
    no-repeat;
  transition: 0.35s;
  font-weight: 300;
}

.ul > li ol > li:hover {
  --d: 100%;
}

.ul:nth-child(1) > li {
  height: fit-content;
  width: fit-content;
  padding: 0;
}

@media (max-width: 1100px) {
  .ul {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-auto-rows: auto;
    grid-gap: 1rem;
    padding: 1rem;
    overflow: auto;
    max-height: calc(100dvh - 230px);
  }

  .ul:nth-child(1) > li {
    width: 100%;
    height: fit-content;
  }

  .ul > li {
    position: relative;
  }

  .ul > li > span:nth-child(2) {
    position: absolute;
    right: 0;
    top: 0;
  }
}

@media (max-width: 640px) {
  .ul > li {
    overflow: hidden;
  }

  .ul > li > ol > li:nth-child(1) {
    padding: 0.5rem 0 0;
  }

  .ul {
    grid-gap: 1.5rem;
    padding: 1.5rem 1rem 1rem;
    max-height: calc(100dvh - 160px);
  }
}
